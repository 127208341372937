// See other presets to try at https://theme-ui.com/packages/presets
import { deep } from "@theme-ui/presets";
import { Theme } from "theme-ui";

const theme: Theme = {
  ...deep,
  sizes: {
    ...[4, 8, 16, 24, 32, 48, 64, 128],
    container: 800,
  },
  // TODO: Select colors from tilesets/topdown.png
  colors: deep.colors,
  styles: {
    root: {
      ...deep.styles.root,
      bg: "background",
    },
    h1: deep.styles.h1,
    h2: deep.styles.h2,
    h3: deep.styles.h3,
    h4: deep.styles.h4,
    h5: deep.styles.h5,
    h6: deep.styles.h6,
    a: deep.styles.a,
    pre: deep.styles.pre,
    code: deep.styles.code,
    inlineCode: deep.styles.inlineCode,
    hr: deep.styles.hr,
    img: deep.styles.img,
    ...{ Container: deep.styles.Container },
  },
};

export const PIXEL_RATIO = 3;

export const TILE_SIZE = 20;

// eslint-disable-next-line import/no-default-export
export default theme;
